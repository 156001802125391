<template>
  <div class="home">
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="/">
          <b>首页</b>
        </router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="content">
      <!-- 汇总展示 -->
      <el-row :gutter="24" class="row">
        <el-col :span="12" v-for="item in list" :key="item.name">
          <el-card class="box-card" shadow="always">
            <div class="clearfix" slot="header">
              <span>{{item.name}}</span>
              <router-link :to="item.linkUrl">
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </router-link>
            </div>
            <div class="index-card">
              <el-row :gutter="24">
                <el-col :span="8">
                  <div class="lineItem">
                    <div class="lineTitle">今日</div>
                    <div class="lineNumbe">{{item.today}}</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="lineItem">
                    <div class="lineTitle">本月</div>
                    <div class="lineNumbe">{{item.month}}</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="lineItem">
                    <div class="lineTitle">本年</div>
                    <div class="lineNumbe">{{item.year}}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <!-- 导航链接 -->
      <el-row :gutter="24">
        <el-col :span="24">
          <el-card class="box-card indexpanel">
            <div slot="header" class="clearfix">
              <span>快捷导航</span>
            </div>
            <span v-for="lin in links" :key="lin.id">
              <a v-if="lin.linkUrl.indexOf('http')!=-1" :href="lin.linkUrl" target="_blank">
                <el-button :round="true" :plain="true" class="text item" type="primary">{{lin.name}}</el-button>
              </a>
              <router-link v-else :to="lin.linkUrl">
                <el-button :round="true" :plain="true" class="text item" type="primary">{{lin.name}}</el-button>
              </router-link>
            </span>
          </el-card>
        </el-col>
      </el-row>

      <!-- 版权 -->
      <div class="copyright">©️{{year}} 千乐微云, 备案编号为：鲁ICP备16030308号-1</div>
    </div>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";

export default {
  name: "home",
  components: {},
  data() {
    return {
      list: [], //汇总列表
      links: [], //链接列表
      year: new Date().getFullYear()
    };
  },
  methods: {
    //获取汇总数据
    initData() {
      var _this = this;
      var link = url.getCollect("getindex");
      $.get(link, {}, data => {
        _this.list = data.list;
        _this.links = data.links;
      });
    }
  },
  created() {
    this.initData();
  }
};
</script>

<style  scoped>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background-color: #f2f2f2;
}
.content {
  padding-top: 20px;
}
.indexpanel .text {
  font-size: 14px;
}

.indexpanel .item {
  margin-bottom: 18px;
  margin-left: 10px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.box-card {
  margin-bottom: 15px;
}
.lineItem {
  line-height: 2em;
  text-align: center;
  font-size: 13px;
}
.lineTitle {
  letter-spacing: 2px;
  font-weight: bold;
}
.lineNumbe {
}
.lineLink {
  font-size: 12px;
}
.copyright {
  margin-top: 50px;
  font-size: 12px;
  text-align: center;
  color: #888;
  letter-spacing: 1px;
  line-height: 2em;
}
</style>
